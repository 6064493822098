import axios from "axios";

const isTesting = false;

const apiClient = axios.create({
  baseURL: isTesting
    ? "https://localhost:7026/api/V1"
    : "https://api.lifeglow.app/api/V1",
});

// Add session ID to the Authorization header
apiClient.interceptors.request.use((config) => {
  const sessionId =
    localStorage.getItem("sessionId") ||
    "BcFND4IgGADgH+RYQRhw8KAHUfuwTbeC2wtIUTGnHTJ/fc/jKCHUM4usGXaIcsEQBzBo7xgB5rE1woKOa2gm9euDGf3gtJ/r/PGOhyTKpYHbdBmDTJM2rV6KP7fq01UnCkFGJ3PR4pJq3KnzUmz6ctbmeM3XUN+/WfYH"; // Retrieve session ID
  if (sessionId) {
    config.headers.Authorization = `Bearer ${sessionId}`;
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});

const pollForResponse = async (
  url,
  requestData,
  isPendingCallback,
  interval = 2000,
  maxRetries = 10
) => {
  let retries = 0;

  const poll = async () => {
    try {
      const response = await apiClient.post(url, requestData);

      // Check the response code
      if (isPendingCallback(response.data)) {
        console.log(`Polling... Attempt ${retries + 1}`);
        retries += 1;

        if (retries < maxRetries) {
          // Wait for the interval before polling again
          await new Promise((resolve) => setTimeout(resolve, interval));
          return poll();
        } else {
          throw new Error("Maximum retries reached. Polling stopped.");
        }
      } else {
        // Stop polling and process the result
        console.log("Final response received:", response.data);
        return response.data;
      }
    } catch (error) {
      console.error("Error during polling:", error.message);
      throw error;
    }
  };

  return poll();
};

// Example of a secure API call
export const fetchUserData = async () => {
  try {
    const response = await apiClient.get("/user");
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const fetchAssemblyAIToken = async () => {
  try {
    const response = await apiClient.get("/assemblytoken.php");
    return response.data;
  } catch (error) {
    console.error("Error fetching assembly AI token:", error);
    throw error;
  }
};

export const fetchDeepgramToken = async (name) => {
  try {
    const response = await apiClient.post("/deepgram.php");
    return response.data;
  } catch (error) {
    console.error("Error fetching assembly AI token:", error);
    throw error;
  }
};

export const saveAnswerAPI = async (answers, currentIndex) => {
  try {
    const answerJson = JSON.stringify(answers);
    await apiClient.post("/SaveAnswer", {
      userId: null,
      surveyId: "3cdbd25b-ccdb-4c86-bc50-78b348a5136f",
      answerJson,
      currentIndex,
    });
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchSurveyQuestion = async (
  answersSoFar,
  isOverwriteCurrent,
  currentIndex
) => {
  try {
    const answers = JSON.stringify({ answersSoFar: answersSoFar });
    const data = await pollForResponse(
      "/CreateSurveyQuestion",
      {
        userId: null,
        surveyId: "3cdbd25b-ccdb-4c86-bc50-78b348a5136f",
        surveyTypeId: "D335E762-74B9-4CB8-A686-C632F820243E",
        answersSoFar: answers,
        isOverwriteCurrent,
        currentIndex: currentIndex + 1,
      },
      (data) => {
        return data.result.result.processStatus == "Q";
      },
      1200,
      60
    );

    const question = JSON.parse(data.result.result.question);
    console.log("question", question);
    return question;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchSurvey = async (surveyId) => {
  try {
    const response = await apiClient.get(`/GetSurvey?surveyId=${surveyId}`);
    const questions = response.data.result.result?.questions;
    const plan = response.data.result.result?.plan || null;
    if (questions) {
      const qlist = [];
      const alist = {};
      questions.forEach((q) => {
        const qq = JSON.parse(q.questionJson);
        const aa = q.answerJson ? JSON.parse(q.answerJson) : "";
        qlist.push(qq);
        alist[qlist.length - 1] = {
          answer: aa,
          category: qq.category,
          question: qq.question,
        };
      });
      return [qlist, alist, plan];
    }
    return [null, null, null];
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchSurveyResult = async (answersSoFar) => {
  try {
    const answers = JSON.stringify({ answersSoFar: answersSoFar });
    const data = await pollForResponse(
      "/CreateSurveyResult",
      {
        userId: null,
        surveyId: "3cdbd25b-ccdb-4c86-bc50-78b348a5136f",
        surveyTypeId: "D335E762-74B9-4CB8-A686-C632F820243E",
        answersSoFar: answers,
        isOverwriteCurrent: false,
        currentIndex: 0,
      },
      (data) => {
        return data.result.result.processStatus == "Q";
      },
      1200,
      60
    );

    const html = JSON.parse(data.result.result.result.html);
    console.log("html", html);
    return html;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
