import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  fetchSurveyQuestion,
  fetchSurveyResult,
  saveAnswerAPI,
  fetchSurvey,
} from "../apiClient";
import LifePlan from "./LifePlan";
import SurveyHeader from "./SurveyHeader";
import { Modal, Button } from "react-bootstrap";
import debounce from "lodash.debounce";
import TimeElapsedCounter from "./TimeElapsedCounter";

const AdaptiveSurvey = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [generating, SetGenerating] = useState("");
  const [htmlResult, setHtmlResult] = useState("");
  const [showTips, setShowTips] = useState(false);
  const hasChangedAnswer = useRef(false);
  const prevAnswers = useRef(null);
  const isBackClick = useRef(false);

  const isInit = useRef(0);

  const fetchQuestion = async (isOverwriteCurrent = false) => {
    setLoading(true);
    try {
      const newQuestion = await fetchSurveyQuestion(
        answers,
        isOverwriteCurrent,
        currentIndex
      );
      setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.error("Error fetching question:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (questions[currentIndex]) {
      setCurrentQuestion(questions[currentIndex]);
    }
    //console.log("questions", questions);
  }, [questions, currentIndex]);

  useEffect(() => {
    if (isBackClick.current) {
      hasChangedAnswer.current = prevAnswers.current != JSON.stringify(answers);
    }
    //console.log("answers", answers);
  }, [answers]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        // Adjust threshold as needed
        document.querySelector(".top-menu").classList.add("scroll-active");
      } else {
        document.querySelector(".top-menu").classList.remove("scroll-active");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Fetch the initial question on load
  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true;
      const surveyId = "3cdbd25b-ccdb-4c86-bc50-78b348a5136f";

      const initialFetch = async () => {
        setLoading(true);
        // create session, create survey, create account after limit or generate plan
        // pay $1.99
        // other survey types: mental health, self-help, ...
        const result = await fetchSurvey(surveyId);
        if (result[0]) {
          if (result[2]) {
            setHtmlResult(result[2].html);
          }
          setQuestions(result[0]);
          setCurrentIndex(result[0].length - 1);
          setAnswers(result[1]);
          setLoading(false);
        } else if (questions.length === 0) {
          fetchQuestion();
        }
      };
      initialFetch();
    }
  }, [isInit]);

  const generateResult = async () => {
    SetGenerating("Busy");
    if (currentQuestion) setCurrentQuestion(null);
    try {
      const html = await fetchSurveyResult(answers);
      setHtmlResult(html);
      SetGenerating("Success");
      console.log("htmlResult", html);
    } catch (error) {
      setHtmlResult("");
      SetGenerating("Error");
      console.error("Error generating result:", error);
    } finally {
    }
  };

  const saveAnswer = useCallback(
    debounce((updatedAnswers, answerIndex) => {
      console.log("saving to db");
      saveAnswerAPI(updatedAnswers, answerIndex);
    }, 1000),
    []
  );

  const handleAnswerUpdate = (currentAnswer) => {
    if (!currentQuestion) return;

    saveAnswer(currentAnswer, currentIndex);
    if (isBackClick.current) {
      prevAnswers.current = JSON.stringify(answers);
    }
    const category = currentQuestion.category;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentIndex]: {
        question: currentQuestion.question,
        category,
        answer: currentAnswer,
      },
    }));
  };

  const navigateBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      isBackClick.current = true;
    }
  };

  function removeKeys(obj, keyToRemove) {
    const keysToRemove = Object.keys(obj)
      .map(Number) // Convert keys to numbers
      .filter((key) => key >= keyToRemove); // Find keys >= keyToRemove

    keysToRemove.forEach((key) => delete obj[key]); // Remove keys from object

    return obj; // Return the modified object
  }

  const removeItems = () => {
    const newQuestions = [...questions];
    const startIndexToRemove = currentIndex + 1;
    newQuestions.splice(
      startIndexToRemove,
      newQuestions.length - startIndexToRemove
    );
    setQuestions(newQuestions);

    const newAnswers = removeKeys(answers, startIndexToRemove);
    setAnswers(newAnswers);
  };

  const navigateForward = async () => {
    const isOverwriteCurrent = isBackClick.current && hasChangedAnswer.current;
    if (currentIndex + 1 < questions.length && !isOverwriteCurrent) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      if (isOverwriteCurrent) {
        console.log("isOverwriteCurrent");
        removeItems();
      }
      await fetchQuestion(isOverwriteCurrent);
    }
    isBackClick.current = false;
    hasChangedAnswer.current = false;
  };

  const handleSelectAll = (e, option) => {
    const checked = e.target.checked;
    const previousAnswers = answers[currentIndex]?.answer || [];
    const updatedAnswers = checked
      ? [...previousAnswers, option]
      : previousAnswers.filter((item) => item !== option);
    handleAnswerUpdate(updatedAnswers);
  };

  const getCategoryIcon = (name) => {
    switch (name) {
      case "Vision":
        return "fa-eye";
      case "Values":
        return "fa-heart";
      case "Goals":
        return "fa-bullseye-arrow";
      case "Strengths":
        return "fa-boxing-glove";
      case "Challenges":
        return "fa-puzzle-piece";
      case "Resources":
        return "fa-box-full";
      case "Strategies":
        return "fa-chess";
    }
    return "fa-info";
  };

  return (
    <div>
      <div className="top-menu">
        <span style={{ display: "flex", alignItems: "center" }}>
          <span className="menu-button">
            <svg viewBox="0 0 24 24" fill="none">
              <path
                fill="currentColor"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6ZM2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12ZM2 18C2 17.4477 2.44772 17 3 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H3C2.44772 19 2 18.5523 2 18Z"
              ></path>
            </svg>
          </span>
          <span>LifeGlow</span>
        </span>
        <img
          src="https://lifeglow.app/logo512.png"
          style={{ width: "36px", margin: "8px" }}
        />
      </div>
      <div className="surveypage">
        {generating === "Busy" && (
          <div className="loading-screen">
            <p>Generating Your Personal Plan...</p>
            <TimeElapsedCounter />
            <img src="/loading.svg" className="loading" alt="" />
            {currentQuestion?.quote && (
              <div style={{ margin: "0 auto", maxWidth: "500px" }}>
                <div className="quote">{currentQuestion.quote}</div>
                <p style={{ color: "#000" }}>{currentQuestion.quoteName}</p>
              </div>
            )}
          </div>
        )}
        {htmlResult && <LifePlan result={htmlResult} onEdit={generateResult} />}
        {generating === "Error" && (
          <div>
            <p>Error.. please try again</p>
            <button onClick={generateResult}>Try again</button>
          </div>
        )}
        {loading ? (
          <div>
            <SurveyHeader questions={questions} answeredQuestions={answers} />
            <div className="loading-screen">
              {currentQuestion ? (
                <p>Loading next question...</p>
              ) : (
                <p>Loading survey</p>
              )}
              <TimeElapsedCounter />
              <img src="/loading.svg" className="loading" alt="" />
              {currentQuestion?.quote ? (
                <div style={{ margin: "0 auto", maxWidth: "500px" }}>
                  <div className="quote">{currentQuestion.quote}</div>
                  <p>{currentQuestion.quoteName}</p>
                </div>
              ) : (
                <div style={{ margin: "0 auto", maxWidth: "500px" }}>
                  <div className="quote">
                    A year from now you may wish you had started today.
                  </div>
                  <p>Karen Lamb</p>
                </div>
              )}
            </div>
          </div>
        ) : currentQuestion && !htmlResult ? (
          <div>
            <SurveyHeader questions={questions} answeredQuestions={answers} />
            <Modal
              show={showTips}
              onHide={() => setShowTips(false)}
              size="sm"
              animation={false}
              dialogClassName="fade-in-modal"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Guide to Answering</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  {currentQuestion.tips.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              </Modal.Body>
            </Modal>
            <div
              className="question"
              style={{
                background:
                  currentQuestion?.questionThemeColor ||
                  "linear-gradient(to right, #fff, #eee)",
                color: currentQuestion?.questionColor || "black",
              }}
            >
              <div className="category-head">
                <span
                  className="category-title"
                  style={{ color: currentQuestion?.questionColor || "black" }}
                >
                  <i
                    className={`fa ${getCategoryIcon(
                      currentQuestion.category
                    )}`}
                  ></i>{" "}
                  {currentQuestion.category}
                </span>
              </div>
              <h2>
                {currentIndex + 1}) {currentQuestion.question}
              </h2>
              <p>
                {currentQuestion.explanation}{" "}
                <span
                  className="question-icon"
                  onClick={() => setShowTips(true)}
                >
                  <i className="fas fa-question-circle"></i>
                </span>
              </p>

              <div className="answer">
                {currentQuestion.type === "text" && (
                  <input
                    type="text"
                    placeholder="Type your answer here..."
                    value={answers[currentIndex]?.answer || ""}
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                    onInput={(e) => {
                      if (e.target.value.trim()) {
                        handleAnswerUpdate(e.target.value);
                      }
                    }}
                  />
                )}
                {currentQuestion.type === "textarea" && (
                  <textarea
                    placeholder="Type your detailed answer here..."
                    value={answers[currentIndex]?.answer || ""}
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "10px",
                      height: "100px",
                    }}
                    onInput={(e) => {
                      if (e.target.value.trim()) {
                        handleAnswerUpdate(e.target.value);
                      }
                    }}
                  />
                )}
                {currentQuestion.type === "selectone" && (
                  <div>
                    {currentQuestion.options.map((option, index) => (
                      <div
                        className="selectoption"
                        key={index}
                        style={{ margin: "10px 0" }}
                      >
                        <label>
                          <input
                            type="radio"
                            name={`group-${currentIndex}`}
                            value={option}
                            checked={
                              answers[currentIndex]?.answer?.includes(option) ||
                              false
                            }
                            onChange={(e) => {
                              handleAnswerUpdate(option);
                            }}
                          />
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
                {currentQuestion.type === "selectall" && (
                  <div>
                    {currentQuestion.options.map((option, index) => (
                      <div
                        className="selectoption"
                        key={index}
                        style={{ margin: "10px 0" }}
                      >
                        <label>
                          <input
                            type="checkbox"
                            value={option}
                            checked={
                              answers[currentIndex]?.answer?.includes(option) ||
                              false
                            }
                            onChange={(e) => {
                              handleSelectAll(e, option);
                            }}
                          />
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          generating === "" &&
          !htmlResult && <p>No questions available. Please refresh the page.</p>
        )}
        {!loading && generating == "" && !htmlResult && currentQuestion && (
          <div className="survey-buttons">
            {currentQuestion.isFinal && (
              <Button
                variant="success"
                onClick={generateResult}
                style={{ marginBottom: "30px" }}
              >
                <svg
                  style={{ width: "16px", marginRight: "8px" }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18 18"
                  class="w-5 h-5 mr-2"
                >
                  <path
                    d="M5.15741 5.05261C5.48008 5.17201 5.48008 5.62838 5.15741 5.74778L4.68251 5.92351C4.00621 6.17377 3.47298 6.70699 3.22273 7.38329L3.047 7.8582C2.9276 8.18086 2.47123 8.18086 2.35183 7.8582L2.1761 7.38329C1.92584 6.70699 1.39262 6.17377 0.716319 5.92351L0.241414 5.74778C-0.0812527 5.62838 -0.0812527 5.17201 0.241414 5.05261L0.716319 4.87688C1.39262 4.62663 1.92584 4.0934 2.1761 3.4171L2.35183 2.9422C2.47123 2.61953 2.9276 2.61953 3.047 2.9422L3.22273 3.4171C3.47298 4.0934 4.00621 4.62663 4.68251 4.87688L5.15741 5.05261Z"
                    fill="white"
                  ></path>
                  <path
                    d="M13.9154 11.905C14.5607 12.1438 14.5607 13.0566 13.9154 13.2954L12.9656 13.6468C11.613 14.1473 10.5466 15.2138 10.046 16.5664L9.69458 17.5162C9.45579 18.1615 8.54304 18.1615 8.30424 17.5162L7.95278 16.5664C7.45227 15.2138 6.38583 14.1473 5.03322 13.6468L4.08341 13.2954C3.43808 13.0566 3.43808 12.1438 4.08341 11.905L5.03322 11.5536C6.38583 11.0531 7.45227 9.98661 7.95278 8.63401L8.30424 7.6842C8.54304 7.03886 9.45579 7.03886 9.69458 7.6842L10.046 8.634C10.5466 9.98661 11.613 11.0531 12.9656 11.5536L13.9154 11.905Z"
                    fill="white"
                  ></path>
                  <path
                    d="M13.9364 0.322667C14.0956 -0.107555 14.7041 -0.107556 14.8633 0.322667L15.0976 0.955873C15.4312 1.85761 16.1422 2.56857 17.0439 2.90225L17.6771 3.13655C18.1074 3.29575 18.1074 3.90425 17.6771 4.06345L17.0439 4.29775C16.1422 4.63143 15.4312 5.34239 15.0976 6.24413L14.8633 6.87733C14.7041 7.30756 14.0956 7.30756 13.9364 6.87733L13.7021 6.24413C13.3684 5.34239 12.6574 4.63143 11.7557 4.29775L11.1225 4.06345C10.6922 3.90425 10.6922 3.29575 11.1225 3.13655L11.7557 2.90225C12.6574 2.56857 13.3684 1.85761 13.7021 0.955874L13.9364 0.322667Z"
                    fill="white"
                  ></path>
                </svg>
                Generate Personal Plan
              </Button>
            )}

            <div>
              <Button
                variant="warning"
                onClick={navigateBack}
                disabled={currentIndex === 0}
              >
                Prev
              </Button>
              <Button
                variant="warning"
                onClick={navigateForward}
                style={{ marginLeft: "15px" }}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        {!htmlResult && (
          <div className="footer-msg">
            LifeGlow is an AI-powered app designed to effortlessly create a
            personalized roadmap tailored specifically to your needs and
            priorities. Your data is kept private and secure, ensuring your
            information remains confidential.
          </div>
        )}
      </div>
    </div>
  );
};

export default AdaptiveSurvey;
