import React, { useState, useEffect } from "react";

const TimeElapsedCounter = () => {
  const [timeElapsed, setTimeElapsed] = useState(0); // Time in seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeElapsed((prevTime) => prevTime + 1);
    }, 1000);

    // Cleanup function to clear the interval when unmounting
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  return <div>{formatTime(timeElapsed)}</div>;
};

export default TimeElapsedCounter;
