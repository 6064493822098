import React from "react";

const SurveyHeader = ({ questions, answeredQuestions }) => {
  const categories = [
    "Vision",
    "Values",
    "Goals",
    "Strengths",
    "Challenges",
    "Resources",
    "Strategies",
  ];

  // Count questions per category
  const categoryCounts = categories.reduce((acc, category) => {
    acc[category] = questions.filter((q) => q.category === category).length;
    return acc;
  }, {});

  // Count answered questions per category
  const answeredCounts = categories.reduce((acc, category) => {
    acc[category] = Object.values(answeredQuestions).filter(
      (q) => q.category === category
    ).length;
    return acc;
  }, {});

  return (
    <div
      style={{
        padding: "5px 10px",
        maxWidth: "600px",
        marginBottom: "20px",
        overflow: "hidden",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {categories.map((category) => {
        const total = categoryCounts[category];
        const answered = answeredCounts[category];
        const progress = total ? (answered / total) * 100 : 0;

        return (
          <div
            key={category}
            style={{
              flex: "0 1 auto",
              margin: "5px",
              display: "flex",
              alignItems: "center",
              minWidth: "100px",
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
              padding: "5px",
            }}
          >
            <span
              style={{
                fontSize: "13px",
                marginRight: "5px",
                whiteSpace: "nowrap",
              }}
            >
              {category}
            </span>
            <div
              style={{
                flex: 1,
                height: "13px",
                backgroundColor: "#eaeaea",
                borderRadius: "5px",
                position: "relative",
                minWidth: "50px",
              }}
            >
              <div
                style={{
                  width: `${progress}%`,
                  backgroundColor: "#4caf50",
                  height: "100%",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
            <span
              style={{
                fontSize: "13px",
                marginLeft: "5px",
                whiteSpace: "nowrap",
              }}
            >
              {answered}/{total}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default SurveyHeader;
