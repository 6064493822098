   import React, { useState, useEffect, useRef } from 'react';
   import { createClient,LiveTranscriptionEvents  } from '@deepgram/sdk';
   import AudioWaveformVisualizer from './AudioWaveformVisualizer';
   import { fetchDeepgramToken } from '../apiClient';
  
   function DeepgramComponent() {
    const [isRecording, setIsRecording] = useState(false);
     const [transcript, setTranscript] = useState('');
     const [status, setStatus] = useState('');
     const connection = useRef(null);
     const mediaStream = useRef(null);

     const startListening = async () => {
      setStatus('Starting...');
      
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaStream.current = stream;

      const apiData = await fetchDeepgramToken('Mine');
      console.log('apidata', apiData);
      const deepgram = new createClient(apiData.key);
      let conn = deepgram.listen.live({
        model: 'nova',
        language: 'en-US',
        smart_format: true,
        punctuate: true,
      });

      conn.on(LiveTranscriptionEvents.Open, ()=>{
        console.log('connection opened');
        setStatus('Recording');
        setIsRecording(true);
      });

      conn.on(LiveTranscriptionEvents.Transcript, (data) => {
        if(data && data.channel.alternatives[0].transcript) {
          console.log('channel', data.channel);
          setTranscript((prev) => prev +" "+ data.channel.alternatives[0].transcript);
        }
      });

      conn.on(LiveTranscriptionEvents.Close, ()=>{
          console.log('connection closed');
          setStatus('Closed');
          setIsRecording(false);
      });

      conn.on(LiveTranscriptionEvents.Error, ()=>{
        console.log('connection error');
        setStatus('Error');
      });

      mediaRecorder.addEventListener('dataavailable', (event) => {
        if (event.data.size > 0 && conn) {
          conn.send(event.data);
        }
      });

      connection.current = conn;
      mediaRecorder.start(1000); // Send audio chunks every 1 second
    };

    const stopListening = () => {
      if (connection.current && isRecording) {
        setStatus('Closing');
        connection.current.requestClose();
        connection.current.finish();
        connection.current = null;
      }
    };

     useEffect(() => {
      return () => stopListening(); // Stop listening on component unmount
    }, []);
  
    return (
      <div className="recordscreen">
        <b>Deepgram</b>
        <div className="real-time-interface">
          <div id="real-time-title" className="real-time-interface__title">{status}</div>
          <button className="open-nested-button" onClick={startListening}>Start</button>
          {" "}
          {isRecording && 
          <button  className="open-nested-button" onClick={stopListening}>Stop</button>}
        </div>
        {isRecording && mediaStream.current && <AudioWaveformVisualizer mediaStream={mediaStream.current} /> }
        <div className="real-time-interface__message">{transcript}</div>
      </div>
    );
   }

   export default DeepgramComponent;