//import logo from './logo.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import SplashScreen from "./components/SplashScreen";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import About from "./components/About";
import Profile from "./components/Profile";
import { Provider } from "react-redux";
import store from "./store";
import AdaptiveSurvey from "./components/AdaptiveSurvey";
import ModalDemo from "./components/ModalDemo";

function App() {
  return (
    <div className="lifeglowapp">
      <AdaptiveSurvey />
    </div>
  );
  /**
  const [currentScreen, setScreen] = useState("home");
  const [isModalOpen, setModalOpen] = useState(false);
  const [showSplash, setShowSplash] = useState(true);

  function isMobileDevice() {
    const isMobileAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isMobileData = navigator.userAgentData && navigator.userAgentData.mobile;
    return isMobileData || (isMobileAgent && isTouchDevice)	
  }

  const isLargeDevice = !isMobileDevice();

  if (showSplash) {
      return <SplashScreen onSplashEnd={() => setShowSplash(false)} />;
  }

  let ScreenComponent;
  switch (currentScreen) {
      case "about":
          ScreenComponent = About;
          break;
      case "profile":
          ScreenComponent = Profile;
          break;
      case "survey":
          ScreenComponent = AdaptiveSurvey;
          break;
      default:
          ScreenComponent = Home;
  }

  return (
    <Provider store={store}>
      <div className={`app ${isLargeDevice?'large':''}`}>
        <ScreenComponent />
        <Footer activeScreen={currentScreen} setScreen={setScreen} openModal={() => setModalOpen(true)} />
        
        <CSSTransition
            in={isModalOpen}
            timeout={300}
            classNames="screen-up"
            unmountOnExit>
            <Modal onClose={() => setModalOpen(false)} />
        </CSSTransition>    
      </div>
  </Provider>
  );
  **/
}

export default App;
