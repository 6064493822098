// components/DataList.js
import React, { useEffect } from "react";
import ShadowDomLoader from "./ShadowDomLoader";

const LifePlan = ({ result, onEdit }) => {
  return (
    <div
      style={{
        maxWidth: "850px",
        margin: "0 auto",
        backgroundColor: "rgba(255,255,255,0.05)",
        boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px;",
      }}
    >
      <ShadowDomLoader html={result} />
    </div>
  );
};

export default LifePlan;
