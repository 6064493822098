import React from "react";

class ShadowDomLoader extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.shadowRoot = null;
  }

  componentDidMount() {
    if (!this.shadowRoot) {
      this.shadowRoot = this.containerRef.current.attachShadow({
        mode: "open",
      });
    }
    this.updateContent();
    this.shadowRoot.addEventListener("click", this.handleLinkClick);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.html !== this.props.html) {
      this.updateContent();
    }
  }

  componentWillUnmount() {
    if (this.shadowRoot) {
      this.shadowRoot.removeEventListener("click", this.handleLinkClick);
    }
  }

  handleLinkClick = (event) => {
    if (
      event.target.tagName === "A" &&
      event.target.getAttribute("href")?.startsWith("#")
    ) {
      event.preventDefault();
      const targetId = event.target.getAttribute("href").slice(1);
      const targetElement = this.shadowRoot.querySelector(`#${targetId}`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  scrollToTop = () => {
    this.shadowRoot.host.scrollIntoView({ behavior: "smooth" });
  };

  updateContent() {
    if (this.shadowRoot) {
      this.shadowRoot.innerHTML = `
        ${this.props.html}
        <style>
          #back-to-top {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            cursor: pointer;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            opacity: 0.7;
            transition: opacity 0.3s ease;
          }
          #back-to-top:hover {
            opacity: 1;
          }
        </style>
        <button id="back-to-top" title="Scroll to top">↑</button>
        `;

      const backToTopButton = this.shadowRoot.getElementById("back-to-top");
      if (backToTopButton) {
        backToTopButton.addEventListener("click", this.scrollToTop);
      }
    }
  }

  render() {
    return <div style={{ color: "#2a2a2a" }} ref={this.containerRef}></div>;
  }
}

export default ShadowDomLoader;
